export enum UserRightTypeEnum
{
	NONE = "8B02766C-EF53-4DDD-A5AE-E78B3D39C3A1", 
	LOOK = "F3FCF484-E1CA-42FF-8D48-007FD4CC1F84", 
	FULL = "FD869FD9-6968-4830-8F79-9E5C59F5A431", 
	OWN_CASES = "98A38CE9-F38C-4BFB-9E6A-62FEE1E33274",
	OWN_HOURS = "A36C8337-51C7-4908-8BC3-B8491403ABA1", 
	OWN_ACCOUNT = "4708D50C-9D4C-4D6A-B221-FE44519D9AF4",
	NOT_LOADED = "00000000-0000-0000-0000-000000000000"
}


export enum UserAreaEnum
{
	CONTACTS = "387F0DD7-5D33-4642-BF68-B63306DEB91A",
 	CONTACT_SET_CLIENTRESPONSIBLE = "21321311-0000-0000-0011-000000000001",
 	CONTACT_CHANGE_CLIENTRESPONSIBLE = "21321311-0000-0000-0011-000000000002",
	CONTACT_DOCUMENTS = "21321311-0000-0000-0011-000000000008",
 	CASE_CHANGE_RESTRICTED_TO_ACTIVE = "21321311-0000-0000-0011-000000000003",
 	CASE_CHANGE_CLOSED_TO_ACTIVE = "21321311-0000-0000-0011-000000000004",
 	CASE_CREATE_NEW = "21321311-0000-0000-0011-000000000005",
	CASE_MANAGMENT = "C60A1BE4-A965-4731-9302-BF4E0DCF5F8E",
	CASE_DOCUMENTS = "B333E9FA-6C8E-4772-8F62-3499760C30CA",
	HOUR_COST_REGISTRATION = "3F0B33A9-11BD-4BB7-A828-538786F75689",
 	PRODUCTION_ANALYZES = "21321311-0000-0000-0011-000000000007",
 	TOTAL_RAPPORTS = "E2033FB2-90E8-41CE-B230-0504C3327BC4",
 	HOUR_REPORTS = "B05C929D-FB71-4845-83C4-3DD585F95C14",
 	ACCOUNT_DOCUMENTS = "21321311-0000-0000-0011-000000000006",
	MAIN_ACCOUNT_ACCOUNTFINISHING = "75E6BB08-9E44-4C49-8A80-D76138B772B3",
	MAIN_ACCOUNT_POST_ACCPLAN_ACCOUNTING  = "D39A5BB4-0FB2-4CFC-B19E-9DB730E43D22",
	MAIN_ACCOUNT_REPORTS  = "2F3FFB26-1575-4AC7-A742-4A120CEFD3E8",
	MAIN_ACCOUNT_BUDGET = "E5C11A4E-A7BA-40C5-976A-B5D22EB8D31D",
 	ESTATE_MANAGMENT = "6DAAEF37-AAB2-4CD5-A693-69E8ADD485BB",
	ESTATE_ACCOUNTS = "618A0058-5ED5-4B55-B284-346BC12225A3",
	OTHER_ACCOUNTS_REPORTS = "FC535957-3727-4FD5-B462-FA033C00F253",
	OTHER_ACCOUNTS_ACCOUNTFINISHING = "EAC0637E-B4BE-4932-8170-10E7AA22BD5A",
 	OTHER_ACCOUNTS_POST_ACCPLAN_ACCOUNTING = "4614BEB3-A79C-4FFB-BA5B-1672A81AC9E5",
	OTHER_ACCOUNTS_BUDGET = "6EEE84EB-5151-45DF-A43F-357A15EDBEB6",
	REGISTERS = "02269F8A-B288-45DE-973E-C85E6816CC95",
	USER_MANAGMENT = "B5AA48A2-ACAB-41FD-9170-52479F4AF46C"
}

