import { Injectable } from '@angular/core';
import { ICaseAccessLogExtendedBE, ICaseSearchResultBE, IUserRightsBE } from '@datalex-software-as/datalex-client';
import { UserAreaEnum, UserRightTypeEnum } from '../util/UserRightUtil';
import { SystemCacheService } from './system-cache.service';


type CaseRights = UserRightTypeEnum.FULL | UserRightTypeEnum.LOOK | UserRightTypeEnum.OWN_CASES | UserRightTypeEnum.NONE;


@Injectable({
  providedIn: 'root'
})
export class UserRightsProviderService {

  constructor(private sys: SystemCacheService) { }

  public UserAreaEnum = UserAreaEnum;
  public UserRightTypeEnum = UserRightTypeEnum;

  public checkAccess(userArea: string): { accessId: UserRightTypeEnum, accessName: string, accessLevel: number } {
    if (this.sys.userRights === undefined) return { accessId: UserRightTypeEnum.NOT_LOADED, accessName: "NOT_LOADED", accessLevel: -1 };


    const userRight = this.sys.userRights.filter((userRight) => userRight.UserAreaId.toUpperCase() === userArea.toUpperCase())[0]?.UserRightTypeId.toUpperCase();

    switch (userRight) {
      case UserRightTypeEnum.FULL:
        return { accessId: UserRightTypeEnum.FULL, accessName: "FULL", accessLevel: 3 };

      case UserRightTypeEnum.LOOK:
        return { accessId: UserRightTypeEnum.LOOK, accessName: "LOOK", accessLevel: 1 };

      case UserRightTypeEnum.NONE:
        return { accessId: UserRightTypeEnum.NONE, accessName: "NONE", accessLevel: 0 };

      case UserRightTypeEnum.OWN_ACCOUNT:
        return { accessId: UserRightTypeEnum.OWN_ACCOUNT, accessName: "OWN_ACCOUNT", accessLevel: 2 };

      case UserRightTypeEnum.OWN_CASES:
        return { accessId: UserRightTypeEnum.OWN_CASES, accessName: "OWN_CASES", accessLevel: 2 };

      case UserRightTypeEnum.OWN_HOURS:
        return { accessId: UserRightTypeEnum.OWN_HOURS, accessName: "OWN_HOURS", accessLevel: 2 };

      default:
        return { accessId: UserRightTypeEnum.NONE, accessName: "NONE", accessLevel: 0 };
    }
  }

  async checkCaseAccessLevel(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (this.sys.userRights === undefined) reject(false);
        this.getCaseAccessLevel().then(resolve).catch(reject);
      } catch {
        reject(false);
      }

    });
  }

  private async getCaseAccessLevel(): Promise<boolean> {
    return this.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId !== UserRightTypeEnum.NONE;
  }


  async checkContactAccessLevel({ newContact }: { newContact?: boolean }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (this.sys.userRights === undefined) reject(false);
        this.getContactAccessLevel(newContact).then(resolve).catch(reject);
      } catch {
        reject(false);
      }
    });
  }


  private async getContactAccessLevel(newContact?: boolean): Promise<boolean> {
    if (newContact) return this.checkAccess(UserAreaEnum.CONTACTS).accessId === UserRightTypeEnum.FULL;

    return this.checkAccess(UserAreaEnum.CONTACTS).accessId !== UserRightTypeEnum.NONE;
  }


  checkCaseAccessOwnCases(roles: { sa: string, sb: string | null }, userId: string) {
    if (roles.sa.toUpperCase() === userId.toUpperCase() || roles.sb?.toUpperCase() === userId.toUpperCase()) return true;

    return false;
  }
  checkDocumentAccess(caseId: string, userArea: string) {

  }

  public checkCaseManagementAccess(): boolean {
    const access = this.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId;

    if (access === UserRightTypeEnum.NOT_LOADED) {
      throw new Error("User rights not loaded");
    }

    return access === UserRightTypeEnum.FULL
  }

  public canEditHoursCosts(): boolean {
    const access = this.checkAccess(UserAreaEnum.HOUR_COST_REGISTRATION).accessId;

    if (access === UserRightTypeEnum.NOT_LOADED) {
      throw new Error("User rights not loaded");
    }

    return access === UserRightTypeEnum.FULL;


  }
  public getHoursCostsAccessLevel(): UserRightTypeEnum {
    return this.checkAccess(UserAreaEnum.HOUR_COST_REGISTRATION).accessId;

  }

  public checkHourRegistrationViewAccess(): boolean {
    const access = this.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId;

    if (access === UserRightTypeEnum.NOT_LOADED) {
      throw new Error("User rights not loaded");
    }

    return access !== UserRightTypeEnum.NONE;
  }

}